import * as React from "react";
import Layout from "../components/layout/layout.en";
import Seo from "../components/seo";
import LandingPage from "../components/landing/landing-page.en";

const IndexPage = () => {
  return (
    <Layout languageToggle="/acceuil">
      <Seo title="Home - RCMP emergency alert" />
      <LandingPage />
    </Layout>
  );
};

export default IndexPage;
