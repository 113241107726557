import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const LandingPage = ({ siteTitle }) => {
  return (
    <main class="container" property="mainContentOfPage" resource="#wb-main" typeof="WebPageElement">
      <h1 id="wb-cont" property="name">
        Locations
      </h1>
      <div class="row">
        <div class="col-md-6">
          <div class="list-group">
            <Link to="/ca/home" class="list-group-item">
              Across Canada
            </Link>
            <Link to="/ab/home" class="list-group-item">
              Alberta
            </Link>
            <Link to="/bc/home" class="list-group-item">
              British Columbia
            </Link>
            <Link to="/mb/home" class="list-group-item">
              Manitoba
            </Link>
            <Link to="/ncr-rcn/home" class="list-group-item">
              National Capital Region
            </Link>
            <Link to="/nb/home" class="list-group-item">
              New Brunswick
            </Link>
            <Link to="/nl/home" class="list-group-item">
              Newfoundland and Labrador
            </Link>
            <Link to="/nt/home" class="list-group-item">
              Northwest Territories
            </Link>
          </div>
        </div>
        <div class="col-md-6">
          <div class="list-group">
            <Link to="/ns/home" class="list-group-item">
              Nova Scotia
            </Link>
            <Link to="/nu/home" class="list-group-item">
              Nunavut
            </Link>
            <Link to="/on/home" class="list-group-item">
              Ontario
            </Link>
            <Link to="/pe/home" class="list-group-item">
              Prince Edward Island
            </Link>
            <Link to="/qc/home" class="list-group-item">
              Quebec
            </Link>
            <Link to="/sk/home" class="list-group-item">
              Saskatchewan
            </Link>
            <Link to="/yt/home" class="list-group-item">
              Yukon
            </Link>
          </div>
        </div>
      </div>
      <div class="pagedetails">
        <dl id="wb-dtmd">
          <dt>Date modified:&#32;</dt>
          <dd>
            <time property="dateModified">2022-01-20</time>
          </dd>
        </dl>
      </div>
    </main>
  );
};

LandingPage.propTypes = {
  siteTitle: PropTypes.string,
};

LandingPage.defaultProps = {
  siteTitle: ``,
};

export default LandingPage;
